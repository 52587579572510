import mixpanel from "mixpanel-browser"

// Initialize Mixpanel
const MIXPANEL_TOKEN = "5e2bb0bba2fb36f33db42f82f60341d7" // Replace with your Mixpanel token
mixpanel.init(MIXPANEL_TOKEN, { debug: true }) // Enable debug for development

// Export helper functions
const Mixpanel = {
  track: (eventName, properties) => {
    mixpanel.track(eventName, properties)
  },
  identify: (userId) => {
    mixpanel.identify(userId)
  },
  alias: (newId) => {
    mixpanel.alias(newId)
  },
  peopleSet: (properties) => {
    mixpanel.people.set(properties)
  },
  reset: () => {
    mixpanel.reset()
  },
}

export default Mixpanel
