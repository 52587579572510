import React, {
  lazy,
  Suspense,
  useLayoutEffect,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";

import { useLocation } from "react-router-dom";

import Logo from "../images/logo.svg";
import MenuOpener from "../images/mobile_menu_opener.png";
import { Helmet } from "react-helmet";

import Iframe from "../components/Iframe/index";
import DemoPopup from "../components/DemoPopup/index";

import CaseStudyModal from "../components/ThankYouModal/CaseStudyModal";
import MerchantModal from "../components/ThankYouModal/MerchantModal";
import ReferralModal from "../components/ThankYouModal/ReferralModal";

import MerchantIframe from "../components/MerchantIframe/index";
import IframePopupComponentCaseStudy from "../components/CaseStudyIframe/index";
import IframePopupComponentWaitList from "../components/WaitListPopup/index";

import Loader from "../components/Loader/Loader";

import useWindowResize from "../hooks/common/useWindowResize";
import Mixpanel from "../mixedPanel";

import CrispChat from "../components/Crisp/CrispChat";

const Banner = lazy(() => import("../components/SubscriptionBanner/index"));
const BannerMobile = lazy(() =>
  import("../components/SubscriptionBanner/mobile")
);
const BannerBottom = lazy(() =>
  import("../components/SubscriptionBanner/BannerBottom")
);
const BannerBottomSlider = lazy(() =>
  import(
    "../components/SubscriptionBanner/BannerBottomSlider/BannerBottomSlider"
  )
);
const SubscriptionImage = lazy(() =>
  import("../components/SubscriptionImage/index")
);
const WhatPizzaSubscription = lazy(() =>
  import("../components/WhatPizzaSubscription/index")
);
const SubscriptionCampaign = lazy(() =>
  import("../components/SubscriptionCampaign/index")
);
const SubscriptionGet = lazy(() =>
  import("../components/SubscriptionGet/index")
);
const SubscriptionBenefits = lazy(() =>
  import("../components/SubscriptionBenefits/index")
);
const FAQ = lazy(() => import("../components/FAQ/index"));
const Footer = lazy(() => import("../components/SubscriptionFooter/index"));

const Home = () => {
  const { screenWidth, screenHeight } = useWindowResize();

  const [queryParams, setQueryParams] = useState("");
  const [queryParamsWithOutHash, setQueryParamsWithOutHash] = useState("");
  const [queryParamsWithOutHashCaseStudy, setQueryParamsWithOutHashCaseStudy] =
    useState("");

  const [queryParamsWithOutHashWaitList, setQueryParamsWithOutHashWaitList] =
    useState("");

  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [openIframePopup, setOpenIframePopup] = useState(false);
  const [openCaseStudyModal, setOpenCaseStudyModal] = useState(false);
  const [openCaseStudyPopup, setOpenCaseStudyPopup] = useState(false);
  const [openMerchantModal, setOpenMerchantModal] = useState(false);
  const [openReferralModal, setOpenReferralModal] = useState(false);
  const [openMerchantIframe, setOpenMerchantIframe] = useState(false);
  const [openDemoPopup, setOpenDemoPopup] = useState(false);
  const [openWaitListPopup, setOpenWaitListPopup] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);

  const topRef = useRef(null);
  const whatIsItRef = useRef(null);
  const howItWorksRef = useRef(null);
  const whatYouGetRef = useRef(null);
  const benefitRef = useRef(null);
  const faqRef = useRef(null);

  const location = useLocation();

  useLayoutEffect(() => {
    const hash = window.location.hash;
    const refsMap = {
      "#WHATISIT": whatIsItRef,
      "#HOWITWORKS": howItWorksRef,
      "#WHATYOUGET": whatYouGetRef,
      "#BENEFITS": benefitRef,
      "#FAQ": faqRef,
    };

    const ref = refsMap[hash];
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    } else if (!hash && topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    Mixpanel.track("Page View", { page: "home" });
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const loadIframe = () => {
    // Preconnect to the domain
    const preconnectLink = document.createElement("link");
    preconnectLink.rel = "preconnect";
    preconnectLink.href = "https://link.msgsndr.com";
    document.head.appendChild(preconnectLink);

    // Preload the script
    const preloadLink = document.createElement("link");
    preloadLink.rel = "preload";
    preloadLink.href = "https://link.msgsndr.com/js/form_embed.js";
    preloadLink.as = "script";
    document.head.appendChild(preloadLink);

    // Append the actual script tag to the document body
    const script = document.createElement("script");
    script.src = "https://link.msgsndr.com/js/form_embed.js";
    script.async = true;
    document.body.appendChild(script);

    // Clean up function
    return () => {
      // Clean up the script and links when the popup is closed
      document.body.removeChild(script);
      document.head.removeChild(preconnectLink);
      document.head.removeChild(preloadLink);
    };
  };

  useEffect(() => {
    loadIframe();

    const hash = window.location.hash;
    const url = window.location.href;
    const params = checkParamsInUrl(url);
    const paramsWithOutHash = checkParamsInUrlWithOutHash(url);
    const paramsBookADemo = checkParamsInUrlWithOutHashBookADemo(url);
    const paramsCaseStudy = checkParamsInUrlWithOutHashCaseStudy(url);
    const paramsWaitList = checkParamsInUrlWithOutHashWaitList(url);

    setQueryParams(
      params
        ? `https://api.leadconnectorhq.com/widget/booking/4dyJhjVr4Ry5TwY6RzsP${params}`
        : "https://api.leadconnectorhq.com/widget/booking/4dyJhjVr4Ry5TwY6RzsP"
    );
    setQueryParamsWithOutHash(
      paramsWithOutHash
        ? `https://api.leadconnectorhq.com/widget/form/UNFtd7cQWfcQTXrO3PwF${paramsWithOutHash}`
        : "https://api.leadconnectorhq.com/widget/form/UNFtd7cQWfcQTXrO3PwF"
    );

    setQueryParamsWithOutHashCaseStudy(
      paramsCaseStudy
        ? `https://api.leadconnectorhq.com/widget/form/8icV18hKzwL3ygpgTVVg${paramsCaseStudy}`
        : "https://api.leadconnectorhq.com/widget/form/8icV18hKzwL3ygpgTVVg"
    );

    setQueryParamsWithOutHashWaitList(
      paramsWaitList
        ? `https://api.leadconnectorhq.com/widget/form/of9JNzgmeagtOwtX9LP8${paramsWaitList}`
        : "https://api.leadconnectorhq.com/widget/form/of9JNzgmeagtOwtX9LP8"
    );

    const referralModalPart = location.pathname.split("/").pop();
    setOpenIframePopup(
      params || referralModalPart === "book-demo" || paramsBookADemo
    );
    setOpenCaseStudyModal(referralModalPart === "casestudymodal");
    setOpenMerchantModal(referralModalPart === "merchantmodal");
    setOpenReferralModal(referralModalPart === "referralmodal");
    setOpenDemoPopup(referralModalPart === "demoModal" || referralModalPart === "demovideo");
    setOpenMerchantIframe(
      referralModalPart === "merchant-info-form" || paramsWithOutHash
    );
    setOpenCaseStudyPopup(
      referralModalPart === "case-study-form" || paramsCaseStudy
    );
    setOpenWaitListPopup(referralModalPart === "waitlist" || paramsWaitList);
  }, [location]);

  const checkParamsInUrl = useCallback((url) => {
    const hashIndex = url.indexOf("#");
    if (hashIndex !== -1) {
      const queryIndex = url.substring(hashIndex + 1).indexOf("?");
      if (queryIndex !== -1) {
        return url.substring(hashIndex + 1 + queryIndex);
      }
    }
    return "";
  }, []);

  const checkParamsInUrlWithOutHash = useCallback((url) => {
    const pathSegment = "/merchant-info-form";
    if (url.includes(pathSegment)) {
      const queryIndex = url.indexOf("?");
      return queryIndex !== -1 ? url.substring(queryIndex) : "";
    }
    return "";
  }, []);

  const checkParamsInUrlWithOutHashCaseStudy = useCallback((url) => {
    const pathSegment = "/case-study-form";
    if (url.includes(pathSegment)) {
      const queryIndex = url.indexOf("?");
      return queryIndex !== -1 ? url.substring(queryIndex) : "";
    }
    return "";
  }, []);

  const checkParamsInUrlWithOutHashBookADemo = useCallback((url) => {
    const pathSegment = "/book-demo";
    if (url.includes(pathSegment)) {
      const queryIndex = url.indexOf("?");
      return queryIndex !== -1 ? url.substring(queryIndex) : "";
    }
    return "";
  }, []);

  const checkParamsInUrlWithOutHashWaitList = useCallback((url) => {
    const pathSegment = "/waitlist";
    if (url.includes(pathSegment)) {
      const queryIndex = url.indexOf("?");
      return queryIndex !== -1 ? url.substring(queryIndex) : "";
    }
    return "";
  }, []);

  const handleScroll = useCallback((ref, id) => {
    setOpenMobileMenu(false);
    const navbarHeight = document.getElementById("navbar").offsetHeight + 800;
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - navbarHeight,
        behavior: "smooth",
      });
    }
  }, []);

  const handleOpenMobileMenu = () => {
    setOpenMobileMenu(!openMobileMenu);
  };

  const handleOpenIframe = () => {
    setOpenIframePopup(true);
  };

  const handleCloseIframePopup = () => {
    setOpenIframePopup(false);
  };

  const handleOpenDemoPopup = (text) => {
    if (text === "fromNav") {
      setOpenMobileMenu(false);
    }
    Mixpanel.track("Book a Demo", { page: "book a demo header" });
    setOpenDemoPopup(true);
  };

  const handleCloseDemoPopup = () => {
    setOpenDemoPopup(false);
  };

  const handleCloseMerchantIframe = () => {
    setOpenMerchantIframe(false);
  };

  const handleCloseCaseStudy = () => {
    setOpenCaseStudyPopup(false);
  };

  const handleCloseCaseStudyModal = () => {
    setOpenCaseStudyModal(false);
  };

  const handleCloseMerchantModal = () => {
    setOpenMerchantModal(false);
  };

  const handleCloseReferralModal = () => {
    setOpenReferralModal(false);
  };

  const handleCloseWaitList = () => {
    setOpenWaitListPopup(false);
  };

  return (
    <>
      <Helmet>
        <title>PizzaBox AI - Pizza Subscription Platform For Pizzerias</title>
        <meta
          name="description"
          content="Launch a pizza subscription with PizzaBox: raise zero-interest capital, ensure weekly sales, and build strong customer loyalty with the best pizza program."
        />
        <meta
          name="keywords"
          content="pizzabox pizza subscription box pizza subscription, pizzeria benefits, local pizza business, subscription
    revenue, customer loyalty, recurring income, Caesar Salad, Cheesecake, Cheeseburger Sub, Cheesesteak, Cookies, French onion soup, Fried Chicken, Pasta, Pierogies, Shrimp, Stromboli, Wings,Sicilian Pizza, New York Pizza, Veggie Pizza, Deep Dish Pizza, By the Slice, Gluten Free Pizza, Neapolitan Pizza, Tomato Pie, Grandma Pizza, Thin Crust Pizza,Stuffed Pizza, Thick Crust Pizza,
    Pizza subscriptions, ai pizza, pizza gpt, Pizza box, Pizza memberships, Pizza loyalty, Pizza loyalty programs, Pizza subscription implementation, Pizza subscription, PizzaBox Benefits, pizza subscription case study, pizza per week, weekly pizza, pizza redemption, yearly pizza subscription, customer retention"
        />
        <link rel="canonical" href="https://pizzabox.ai/" />
        <meta
          property="og:title"
          content="PizzaBox AI - Pizza Subscription Platform For Pizzerias"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Launch a pizza subscription with PizzaBox: raise zero-interest capital, ensure weekly sales, and build strong customer loyalty with the best pizza program."
        />
        <meta property="og:url" content="https://pizzabox.ai/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="PizzaBox AI - Pizza Subscription Platform For Pizzerias"
        />
        <meta name="twitter:url" content={`https://pizzabox.ai/`} />

        <meta
          name="twitter:description"
          content="Launch a pizza subscription with PizzaBox: raise zero-interest capital, ensure weekly sales, and build strong customer loyalty with the best pizza program."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebSite",
            name: "PizzaBox AI",
            alternateName: "PizzaBox AI",
            url: "https://pizzabox.ai/",
            description:
              "Launch a pizza subscription with PizzaBox: raise zero-interest capital, ensure weekly sales, and build strong customer loyalty with the best pizza program.",
          })}
        </script>
      </Helmet>
      <Suspense
        fallback={
          <>
            <Loader />
            <CrispChat />
          </>
        }
      >
        <div className="bg-[#F8F4E6]">
          <div className="bg-[#2A2B2C] h-auto rounded-bl-[4rem] md:rounded-bl-[8rem] xl:rounded-bl-[10rem] rounded-br-[4rem] md:rounded-br-[8rem] xl:rounded-br-[10rem]">
            <div className="px-5 md:px-8 xl:px-0">
              <div id="TOP" ref={topRef}></div>
              <div
                className={`w-full fixed top-0 z-50 px-6 lg:px-0 right-0 ${
                  isScrolled ? "bg-[#2A2B2C]" : "bg-transparent"
                }`}
                id="navbar"
              >
                <div className="w-full md:container md:pr-0">
                  <nav className="pt-0 lg:pt-2">
                    <div className="py-2 flex flex-col xl:flex-row justify-between items-start">
                      <div className="text-2xl font-bold w-full xl:w-[37%] flex justify-between">
                        <img
                          src={Logo}
                          alt="Logo"
                          className="w-[70%] 2xl:w-[60%]"
                          width={426}
                          height={104}
                        />
                        <div className="xl:hidden">
                          <img
                            src={MenuOpener}
                            alt="menu opener"
                            className="w-12 sm:w-16 md:w-20 lg:w-28"
                            onClick={handleOpenMobileMenu}
                          />
                        </div>
                      </div>

                      <div
                        className={`w-full xl:w-[63%] xl:flex transition-all duration-300 ease-in-out ${
                          openMobileMenu ? "" : "hidden"
                        }`}
                      >
                        <div className="w-full flex flex-col xl:flex-row bg-[#FFBD00] justify-between items-center gap-1 rounded-3xl xl:rounded-full">
                          <a
                            href="#WHATISIT"
                            className="relative py-3 sm:py-6 xl:py-0 pl-4 xl:pl-8 pr-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow"
                            onClick={() =>
                              handleScroll(whatIsItRef, "WHATISIT")
                            }
                          >
                            WHAT IS IT
                          </a>
                          <a
                            href="#HOWITWORKS"
                            className="relative py-3 sm:py-6 xl:py-0 px-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow "
                            onClick={() =>
                              handleScroll(howItWorksRef, "HOWITWORKS")
                            }
                          >
                            HOW IT WORKS
                          </a>
                          <a
                            href="#WHATYOUGET"
                            className="relative py-3 sm:py-6 xl:py-0 px-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow "
                            onClick={() =>
                              handleScroll(whatYouGetRef, "WHATYOUGET")
                            }
                          >
                            WHAT YOU GET
                          </a>
                          <a
                            href="#BENEFITS"
                            className="relative py-3 sm:py-6 xl:py-0 px-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow "
                            onClick={() => handleScroll(benefitRef, "BENEFITS")}
                          >
                            BENEFITS
                          </a>
                          <a
                            href="#FAQ"
                            className="py-3 sm:py-6 xl:py-0 px-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow "
                            onClick={() => handleScroll(faqRef, "FAQ")}
                          >
                            FAQ
                          </a>
                          <button
                            onClick={() => handleOpenDemoPopup("fromNav")}
                            className="text-white py-3 sm:py-6 xl:py-2 bg-black font-semibold px-[40px] text-[24px] sm:text-[26px] xl:text-[22px] rounded-3xl xl:rounded-full w-full xl:w-64 text-center"
                          >
                            Book a demo
                          </button>
                        </div>
                      </div>
                    </div>
                  </nav>
                  <div className="mt-[-5px] lg:mt-2">
                    <p className="uppercase font-sfPro text-[12px] lg:text-[27px] xl:text-[22px] text-[#fff] lg:block hidden">
                      <span className="text-[#FBCD33]">[Smart]</span> Pizza
                      Subscription Platform For Pizzerias
                    </p>
                    <p className="uppercase font-sfPro text-[14px] lg:text-[27px] text-[#fff] lg:hidden">
                      <span className="text-[#FBCD33]">[SMART]</span> Pizza
                      Subscription Platform
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#2A2B2C] h-auto rounded-bl-[0rem] lg:rounded-bl-[0rem] xl:rounded-bl-[10rem] rounded-br-[0rem] lg:rounded-br-[0rem] xl:rounded-br-[10rem]">
            {/* {screenWidth <= 1024 ? (
                <div className="w-full pt-24 lg:pt-0">
                <BannerMobile handleOpenPopup={handleOpenDemoPopup} />
                </div>
              ) : (
                <div className="w-full pt-16 lg:pt-0">
                <Banner handleOpenPopup={handleOpenDemoPopup} />
                </div>
              )} */}
            <div className="w-full pt-16 lg:pt-0">
              <Banner handleOpenPopup={handleOpenDemoPopup} />
            </div>
            <div className="w-full md:container px-5 xl:px-0">
              {screenWidth <= 1024 ? <BannerBottomSlider /> : <BannerBottom />}
            </div>
          </div>
        </div>
        <div className="bg-[#EAE4CE]">
          <div className="bg-[#F8F4E6] w-full px-0">
            <SubscriptionImage handleOpenPopup={handleOpenDemoPopup} />
          </div>
        </div>

        <div className="bg-[#EAE4CE]">
          <div className="bg-[#F8F4E6] h-auto rounded-br-[4rem] md:rounded-br-[8rem] xl:rounded-br-[10rem]">
            <div className="w-full px-0">
              <WhatPizzaSubscription
                id="WHATISIT"
                ref={whatIsItRef}
                handleOpenPopup={handleOpenDemoPopup}
              />
            </div>
          </div>
        </div>
        <div className="bg-[#F8F4E6]">
          <div className="bg-[#EAE4CE] h-auto rounded-bl-[4rem] md:rounded-bl-[8rem] xl:rounded-bl-[10rem]">
            <div className="w-full md:container px-5 xl:px-0">
              <SubscriptionCampaign
                id="HOWITWORKS"
                ref={howItWorksRef}
                queryParamsWithOutHashCaseStudy={
                  queryParamsWithOutHashCaseStudy
                }
              />
            </div>
          </div>
        </div>
        <div className="bg-[#EAE4CE]">
          <div className="bg-[#F8F4E6] h-auto rounded-br-[4rem] md:rounded-br-[8rem] xl:rounded-br-[10rem]]">
            <div className="w-full md:container px-5 xl:px-0">
              <SubscriptionGet id="WHATYOUGET" ref={whatYouGetRef} />
            </div>
          </div>
        </div>
        <div className="bg-[#EAE4CE] h-auto rounded-bl-[4rem] md:rounded-bl-[8rem] xl:rounded-bl-[10rem]">
          <div className="w-full md:container">
            <SubscriptionBenefits
              id="BENEFITS"
              ref={benefitRef}
              handleOpenPopup={handleOpenDemoPopup}
            />
          </div>
        </div>
        <div className="bg-[#000]">
          <div className="bg-white h-auto rounded-br-[4rem] md:rounded-br-[8rem] xl:rounded-br-[10rem]">
            <div className="w-full md:container px-5 xl:px-0">
              <FAQ id="FAQ" ref={faqRef} />
            </div>
          </div>
        </div>
        <div className="bg-[#000] h-auto">
          <div className="w-full md:container">
            <Footer />
          </div>
        </div>

        <Iframe
          openIframePopup={openIframePopup}
          onClose={handleCloseIframePopup}
          queryParams={queryParams}
        />

        {openDemoPopup && (
          <DemoPopup
            openDemoPopup={openDemoPopup}
            onClose={handleCloseDemoPopup}
            queryParams={queryParams}
            handleOpenPopup={handleOpenIframe}
          />
        )}

        {openCaseStudyModal && (
          <CaseStudyModal
            open={openCaseStudyModal}
            onClose={handleCloseCaseStudyModal}
          />
        )}
        {openMerchantModal && (
          <MerchantModal
            open={openMerchantModal}
            onClose={handleCloseMerchantModal}
          />
        )}
        {openReferralModal && (
          <ReferralModal
            open={openReferralModal}
            onClose={handleCloseReferralModal}
          />
        )}
        {openMerchantIframe && (
          <MerchantIframe
            openIframePopup={openMerchantIframe}
            onClose={handleCloseMerchantIframe}
            queryParams={queryParamsWithOutHash}
          />
        )}

        {openCaseStudyPopup && (
          <IframePopupComponentCaseStudy
            openIframePopup={openCaseStudyPopup}
            onClose={handleCloseCaseStudy}
            queryParamsWithOutHashCaseStudy={queryParamsWithOutHashCaseStudy}
          />
        )}
        {openWaitListPopup && (
          <IframePopupComponentWaitList
            openIframePopup={openWaitListPopup}
            onClose={handleCloseWaitList}
            queryParamsWithOutHashWaitList={queryParamsWithOutHashWaitList}
          />
        )}
      </Suspense>
    </>
  );
};

export default Home;
