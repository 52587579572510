import React from "react"
import HttpClient from "../../utils/ga/httpClient"
import moment from "moment"
import Card from "react-bootstrap/Card"
import Header from "../HeaderComponent"
import FooterComponent from "../FooterComponent/index"
import Loader from "../Loader/Loader"
import { Helmet } from "react-helmet-async"
import Mixpanel from "../../mixedPanel"

const Index = () => {
  const [allNewsData, setAllNewsData] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    getNewsData()
    Mixpanel.track("Page View", { page: "press" })
  }, [])

  const getNewsData = async () => {
    setLoading(true)
    let data = {
      limit: 0,
      offset: 0,
    }
    let result = await HttpClient.requestDataNews("get", "POST", data)
    if (result) {
      setLoading(false)
      const sortedNewsPosts = result.data.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      )
      const filter = sortedNewsPosts.filter((item) => item.isPizzaBox === true)
      setAllNewsData(filter)
    }
  }

  const handleContinue = (link) => {
    window.open(link, "_blank")
  }

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>Press - PizzaBox AI</title>
        <meta
          name="keywords"
          content="Customer Loyalty, Flintridge Pizza Kitchen, Upselling, upselling opportunities, Box, SubscriptionBox, Pizza subscription near me, pizza near me, monthly pizza, weekly pizza, pizza of the month, pizza club, online ordering, pizza delivery, pizza subscription service,pizza monthly subscription, pizza box online, pizza box website, pizza subscription, pizzeria benefits, local pizza business, subscription
    revenue, customer loyalty, recurring income, Caesar Salad, Cheesecake, Cheeseburger Sub, Cheesesteak, Cookies, French onion soup, Fried Chicken, Pasta, Pierogies, Shrimp, Stromboli, Wings,Sicilian Pizza, New York Pizza, Veggie Pizza, Deep Dish Pizza, By the Slice, Gluten Free Pizza, Neapolitan Pizza, Tomato Pie, Grandma Pizza, Thin Crust Pizza,Stuffed Pizza, Thick Crust Pizza,
    Pizza subscriptions, ai pizza, pizza gpt, Pizza box, Pizza memberships, Pizza loyalty, Pizza loyalty programs, Pizza subscription implementation, Pizza subscription, PizzaBox Benefits, pizza subscription case study, pizza per week, weekly pizza, pizza redemption, yearly pizza subscription, customer retention, Customer Loyalty, Flintridge Pizza Kitchen, Upselling, upselling opportunities"
        />
        <meta
          name="description"
          content="Explore the latest press coverage, media mentions, and news about PizzaBox AI, the leading pizza subscription platform for pizzerias."
        />
        <meta name="robots" content="index, follow"></meta>
        <link rel="canonical" href="https://pizzabox.ai/press" />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Press - PizzaBox AI" />
        <meta
          property="og:description"
          content="Explore the latest press coverage, media mentions, and news about PizzaBox AI, the leading pizza subscription platform for pizzerias."
        />
        <meta property="og:url" content="https://pizzabox.ai/press" />
        <meta property="og:type" content="website" />
        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Press - PizzaBox AI" />
        <meta
          name="twitter:description"
          content="Explore the latest press coverage, media mentions, and news about PizzaBox AI, the leading pizza subscription platform for pizzerias."
        />{" "}
        <meta name="twitter:url" content={`https://pizzabox.ai/press`} />
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "Press - PizzaBox AI",
            url: "https://pizzabox.ai/press",
            description:
              "Explore the latest press coverage, media mentions, and news about PizzaBox AI, the leading pizza subscription platform for pizzerias.",
          })}
        </script>
      </Helmet>

      {loading ? (
        <Loader />
      ) : (
        <div className="bg-[#F8F4E6]">
          <div className="px-0 md:px-8 xl:px-0">
            <div id="TOP"></div>
            <Header />
            <div className="pt-44 sm:pt-48">
              <div className="w-full md:container px-5 xl:px-0">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {allNewsData.map((item, index) => {
                    return (
                      <div key={item.id}>
                        <Card
                          style={{
                            border: "2px solid #EEE",
                            height: "100%",
                          }}
                          className="p-10 bg-white"
                        >
                          <Card.Body>
                            <div className="w-[275px] sm:w-[280px] h-[30px] flex items-center justify-center text-black text-[14px] sm:text-[16px] font-semibold leading-[46px] uppercase bg-[#ffbd00] mb-[25px]">
                              {item?.author_name}
                            </div>
                            <Card.Title
                              className="text-[#061737] sm:text-[20px] text-[24px] font-bold leading-[30px] uppercase cursor-pointer"
                              onClick={() => handleContinue(item.link)}
                            >
                              {item.title}
                            </Card.Title>
                            <Card.Text className="text-[#4f457d] text-[16px] font-normal leading-[25px] mt-[15px]">
                              {moment.utc(item.date).format("ll")}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <FooterComponent />
          </div>
        </div>
      )}
    </>
  )
}

export default Index
