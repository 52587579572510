import React, { useEffect, useState } from 'react';
import Mixpanel from "../../mixedPanel";

const CrispChat = () => {
    const [openDemoPopup, setOpenDemoPopup] = useState(false);

    const handleOpenDemoPopup = () => {
        Mixpanel.track("Book a Demo", { page: "book a demo header" });
        setOpenDemoPopup(true);
    };

    const mobileWidthThreshold = 768;

    const isPc = () => {
        return window.innerWidth > mobileWidthThreshold;
    };

    useEffect(() => {
        if (!window.$crisp) {
            window.$crisp = [];
            window.CRISP_WEBSITE_ID = "d29efe6d-f14c-48fe-997a-8bd6deb1dc7e";
            const script = document.createElement("script");
            script.src = "https://client.crisp.chat/l.js";
            script.async = 1;
            document.head.appendChild(script);


            if (isPc()) {
                window.addEventListener("load", () => {
                    window.$crisp.push(["do", "chat:open"]);
                });
            }

            script.onload = () => {

                window.$crisp.push(["do", "session:reset"]);

                if (isPc()) {
                    pushSignupEvent();
                }

                setTimeout(() => { //fix

                    if (!isPc()) {
                        pushSignupEvent();
                    }

                }, 7000);
            };
        }
    }, []);

    const pushSignupEvent = () => {
        if (window.$crisp) {
            if (isPc()) {
                window.$crisp.push(["do", "chat:open"]);
            }
            window.$crisp.push(["do", "message:show", ["text", "${youtube}[Video label](L9cRrq4F0W0)"]]);
            window.$crisp.push(["do", "message:show", ["text", "Hey there! Enter your question and we’ll get back to you shortly."]]);

        }
    };

    return null;
};

export default CrispChat;
