import React, { useEffect, useState } from "react"

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    // Check if the user has already accepted the cookies
    const hasAccepted = localStorage.getItem("cookiesAccepted")
    if (!hasAccepted) {
      setShowBanner(true)
    }
  }, [])

  const handleAccept = () => {
    setShowBanner(false)
    // Store user's consent in local storage
    localStorage.setItem("cookiesAccepted", "true")
  }

  if (!showBanner) return null

  return (
    <div className="fixed bottom-0 w-full bg-gray-900 text-white flex items-center justify-between p-4 z-50">
      <div className="text-sm">
        We use cookies to ensure you have the best browsing experience on our
        website. By using our site, you acknowledge that you have read and
        understood our{" "}
        <a
          href="https://restogpt.ai/privacy-policy"
          className="text-green-500 underline hover:text-green-400"
        >
          Cookie Policy
        </a>{" "}
        &{" "}
        <a
          href="https://restogpt.ai/privacy-policy"
          className="text-green-500 underline hover:text-green-400"
        >
          Privacy Policy
        </a>
        .
      </div>
      <button
        onClick={handleAccept}
        className="bg-green-500 hover:bg-green-400 text-white py-1 px-4 rounded"
      >
        Got It!
      </button>
    </div>
  )
}

export default CookieBanner
