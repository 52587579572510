import React, { useEffect, useRef } from "react";
import CloseImg from "../../images/popup/black-close.png";
import "./index.css";

const Index = ({ openDemoPopup, onClose, handleOpenPopup }) => {
  const iframeContainerRef = useRef(null); // Container for iframe
  const playerRef = useRef(null); // YouTube Player instance

  useEffect(() => {
    // Load and initialize YouTube Player API
    const loadYouTubeAPI = () => {
      if (window.YT && window.YT.Player) {
        initializePlayer();
      } else {
        const script = document.createElement("script");
        script.src = "https://www.youtube.com/iframe_api";
        script.async = true;
        script.onload = () => window.YT.ready(initializePlayer);
        document.body.appendChild(script);
      }
    };
    const initializePlayer = () => {
      if (!playerRef.current && iframeContainerRef.current) {
        // Create a new iframe dynamically
        const iframe = document.createElement("iframe");
        // iframe.src = "https://www.youtube.com/embed/L9cRrq4F0W0?enablejsapi=1";
        iframe.src = "https://www.youtube.com/embed/Ign7OmDNet0?enablejsapi=1";
        iframe.title = "YouTube video player";
        iframe.frameBorder = "0";
        iframe.allow =
          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share";
        iframe.allowFullscreen = true;

        // Append the iframe to the container
        iframeContainerRef.current.appendChild(iframe);

        // Initialize YouTube player
        playerRef.current = new window.YT.Player(iframe, {
          events: {
            onReady: (event) => {
              if (openDemoPopup) {
                // event.target.playVideo();
              }
            },
          },
        });
      }
    };

    if (openDemoPopup) {
      loadYouTubeAPI();
    }

    return () => {
      // Cleanup on close
      if (playerRef.current) {
        playerRef.current.destroy();
        playerRef.current = null;
      }

      if (iframeContainerRef.current) {
        iframeContainerRef.current.innerHTML = ""; // Remove iframe
      }
    };
  }, [openDemoPopup]);

  return (
    <>
      {openDemoPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-40">
          <div className="w-full md:w-4/5 xl:w-2/5 h-auto bg-[#F8F4E6] border-4 border-[#061737] rounded-[20px] overflow-hidden relative z-50 flex flex-col custom-scrollbar">
            <button
              className="absolute top-1.5 lg:top-2.5 right-1.5 lg:right-2.5 bg-transparent text-black border-none p-2.5 cursor-pointer z-50"
              onClick={onClose}
            >
              <img src={CloseImg} alt="CloseImg" className="w-6 lg:w-8" />
            </button>

            <div className="py-6 px-0 lg:py-6 lg:px-0 pt-14 lg:pt-16 pb-4 lg:pb-3 rounded-[20px]">
              <div
                className="responsive-video"
                ref={iframeContainerRef} // Reference for dynamically adding iframe
              ></div>
              <div className="mt-8 mb-8 w-full flex justify-center">
                <button
                  className="font-sfPro font-semibold bg-[#BE0009] text-white py-3 px-7 md:py-5 xl:py-2 rounded-full xl:text-[22px] text-[18px] sm:text-[38px] lg:text-[40px] mx-auto text-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                  onClick={handleOpenPopup}
                >
                  Book A Live Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
